import { CSS } from "../../constants/css";

const instance = ($el: HTMLElement) => {
  const dom = {
    $menu: $el.querySelectorAll<HTMLElement>(".js-menu"),
    $toggleBtn: $el.querySelector<HTMLElement>(".js-menu-toggle"),
  };

  const setupListeners = () => {
    dom.$toggleBtn?.addEventListener("click", toggle);
  };

  const toggle = () => {
    dom.$menu.forEach(($menu) => {
      $menu.classList.toggle(CSS.active);
    });

    dom.$toggleBtn?.classList.toggle(CSS.active);
  };

  setupListeners();
};

export default () => {
  const $nav = document.querySelectorAll<HTMLElement>(".js-nav");

  $nav.forEach(($element) => {
    instance($element);
  });
};
